import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//首页
const Index = () =>
  import("../views/Index/Index.vue");
//职位
const Position = () =>
  import('../views/Position/Position.vue')
// 职位详情
const PositionDetails = () =>
  import('../views/PositionDetails/PositionDetails.vue')
//关于我们
const About = () =>
  import("../views/About/About.vue");
//社保管理
const SocialSecurity = () =>
  import('../views/About/AboutCom/SocialSecurity.vue')
// 岗位外包
const PostOutsourcing = () =>
  import('../views/About/AboutCom/PostOutsourcing.vue')
//劳务派遣
const LaborDispatch = () =>
  import('../views/About/AboutCom/LaborDispatch.vue')
//灵活用工
const FlexibleEmployment = () =>
  import('../views/About/AboutCom/FlexibleEmployment.vue')
//高端猎头
const HighEndHeadhunters = () =>
  import('../views/About/AboutCom/HighEndHeadhunters.vue')
//app
const App = () =>
  import("../views/App/app.vue");
//新闻
const News = () =>
  import("../views/News/news.vue");
//咨询详情页
const NewsDetail = () =>
  import("../views/NewsDetail/NewsDetail.vue");
//商城
const Shop = () =>
  import('../views/Shop/Shop.vue')
//知识
const Knowledge = () =>
  import('../views/Knowledge/Knowledge.vue')
// 软件详情
const SortDetails = () =>
  import('../views/Knowledge/SortDetails/SortDetails.vue')
// 知识详情
const KnowledgeDetails = () =>
  import('../views/Knowledge/KnowledgeDetails/KnowledgeDetails.vue')
// 低空经济详情
const lowEconomy = () =>
  import('../views/Knowledge/lowEconomy/lowEconomy.vue')
// 低空经济更多
const lowEconomyMore = () =>
  import('../views/Knowledge/lowEconomy/lowEconomyMore.vue')
//干货知识
const Dryknowledge = () =>
  import('../views/Knowledge/Dryknowledge/Dryknowledge.vue')

//科技
const Technology = () =>
  import('../views/Knowledge/Technology/Technology.vue')
//热榜
const HotList = () =>
  import('../views/Knowledge/HotList/HotList.vue')

// 论坛
import indexView from '../views/luntan/indexView.vue'
import userInfo from '../views/luntan/userInfo/userInfo.vue'
import PostView from '../views/luntan/Post/PostView.vue'
import myInterest from '../views/luntan/interest/myInterest.vue'
import remindView from '../views/luntan/remind/remindView.vue'
import news from '../views/luntan/news/newsView.vue'
import searchView from '../views/luntan/search/searchView.vue'
import userEdit from '../views/luntan/userEdit/userEdit.vue'
import questionsAndAnswers from '../views/luntan/questionsAndAnswers/questionsAndAnswers.vue'
import QAPage from '../views/luntan/Q&APage/Q&APage.vue'
import invitationView from '../views/luntan/invitation/invitationView.vue'
import lectureHallInfo from "../views/Knowledge/lectureHallInfo/lectureHallInfo.vue"
import inviteTenders from "../views/inviteTenders/inviteTenders.vue"
import videoPlay from '../views/Knowledge/lectureHallInfo/video.vue'
import myCourse from '../views/Knowledge/lectureHallInfo/myCourse.vue'
import kaotiInfo from '../views/Knowledge/lectureHallInfo/kaotiInfo.vue'
import Staging from '../views/Staging/Staging.vue'
import personnelApplication from '../views/personnelApplication/personnelApplication.vue'
import financialApplication from '../views/financialApplication/financialApplication.vue'
import purchaseRequest from '../views/purchaseRequest/purchaseRequest.vue'
import projectManagement from '../views/projectManagement/projectManagement.vue'
import projectTask from '../views/projectManagement/projectTask.vue'
import createProject from '../views/projectManagement/createProject.vue'
import addTask from '../views/projectManagement/addTask.vue'
import taskDetail from '../views/projectManagement/taskDetail.vue'
import writeJournal from '../views/journal/writeJournal.vue'
import viewingLogs from '../views/journal/viewingLogs.vue'
import templateDetails from '../views/journal/templateDetails.vue'
import approvalCenter from '../views/approval/approvalCenter.vue'
import approvalDetail from '../views/approval/approvalDetail.vue'
import nowNotice from '../views/nowNotice/nowNotice.vue'

const routes = [{
  //首页
  path: "/",
  name: "index",
  component: Index,
},
{
  //首页
  path: "/index",
  name: "index",
  component: Index,
}, {
  //职位
  path: "/position",
  name: "position",
  component: Position,
}, {
  //职位详情
  path: '/positiondetails/:id',
  name: 'PositionDetails',
  component: PositionDetails
},
{
  //关于我们
  path: "/about",
  name: "about",
  component: About,
}, {
  //社保管理
  path: '/socialsecurity',
  name: 'socialsecurity',
  component: SocialSecurity
}, {
  //岗位外包
  path: '/postoutsourcing',
  name: 'postoutsourcing',
  component: PostOutsourcing
}, {
  //劳务派遣
  path: '/labordispatch',
  name: 'labordispatch',
  component: LaborDispatch
}, {
  //灵活用工
  path: '/flexibleemployment',
  name: 'flexibleemployment',
  component: FlexibleEmployment
}, {
  //高端猎头
  path: '/highendheadhunters',
  name: 'highendheadhunters',
  component: HighEndHeadhunters
},
{
  //App
  path: "/app",
  name: "app",
  component: App,
},
{
  //资讯 
  path: "/news",
  name: "news",
  component: News,
},
{
  //资讯详情
  path: "/newsdetail",
  name: "newsdetail",
  component: NewsDetail,
}, {
  //商城
  path: "/shop",
  name: "shop",
  component: Shop,
}, {
  //知识
  path: '/knowledge',
  name: 'knowledge',
  component: Knowledge
}, {
  //干货知识
  path: '/dryknowledge',
  name: 'dryknowledge',
  component: Dryknowledge
}, {
  //热榜
  path: '/hotList',
  name: 'HotList',
  component: HotList
}, {
  //科技
  path: '/technology',
  name: 'technology',
  component: Technology
}, {
  //软件详情
  path: '/sortdetails',
  name: 'sortdetails',
  component: SortDetails
}, {
  //知识详情
  path: '/knowledgeDetails',
  name: 'knowledgeDetails',
  component: KnowledgeDetails
},
{
  //低空经济详情
  path: '/lowEconomy',
  name: 'lowEconomy',
  component: lowEconomy
},
{
  //低空经济更多
  path: '/lowEconomyMore',
  name: 'lowEconomyMore',
  component: lowEconomyMore
},
{
  // 论坛首页
  path: '/luntan/index',
  name: 'luntanIndex',
  component: indexView

},
{
  // 论坛个人资料
  path: '/luntan/userInfo',
  component: userInfo
},
{
  // 
  path: '/luntan/PostView',
  component: PostView,
}, {
  path: '/luntan/myInterest',
  component: myInterest,
}, {
  path: '/luntan/news',
  component: news,
}, {
  path: '/luntan/remindView',
  component: remindView,

}, {
  path: '/luntan/search',
  component: searchView,

}, {
  path: '/luntan/userEdit',
  component: userEdit,

}, {
  path: '/luntan/questionsAndAnswers',
  component: questionsAndAnswers
}, {
  path: '/luntan/QAPage',
  component: QAPage
}, {
  path: '/luntan/invitationView',
  component: invitationView
},
{
  path: '/lectureHallInfo',
  component: lectureHallInfo
},
{
  path: '/myCourse',
  component: myCourse
},
{
  path: '/inviteTenders',
  component: inviteTenders
},
{
  path: '/videoPlay',
  component: videoPlay
},
//我的工作台
{
  path: '/Staging',
  component: Staging
},

// 项目管理
{
  path: '/personnelApplication',
  component: personnelApplication
},
{
  path: '/financialApplication',
  component: financialApplication
},
{
  path: '/purchaseRequest',
  component: purchaseRequest
},
{
  path: '/projectManagement',
  component: projectManagement
},
{
  path: '/projectTask',
  component: projectTask
},
{
  path: '/createProject',
  component: createProject
},
{
  path: '/addTask',
  component: addTask
},
{
  path: '/taskDetail',
  component: taskDetail
},

// 日志模块
{
  path: '/writeJournal',
  component: writeJournal
},
{
  path: '/viewingLogs',
  component: viewingLogs
},
{
  path: '/templateDetails',
  component: templateDetails
},

//审批模块
{
  path: '/approvalCenter',
  component: approvalCenter
},
{
  path: '/approvalDetail',
  component: approvalDetail
},

//消息模块
{
  path: '/nowNotice',
  component: nowNotice
},

{
  path: '/kaotiInfo',
  component: kaotiInfo
},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({
    y: 0
  }),
});

export default router;